import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: '2rem',
    padding: '1rem',
    minHeight: 'calc(100vh - 4rem)',
    width: 'calc(100vw - 4rem)',
    maxWidth: '960px',
    display: 'flex',
    flexDirection: 'column',
    fontSize: 'calc(10px + 2vmin)',
    '& section.single:last-child': {
      marginBottom: '0.5rem',
    },
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      minHeight: '100vh',
      width: '100vw',
    },
  },
  infoContainer: {
    display: 'flex',
    alignItems: 'space-between',
    '& > .me': {
      flex: '1 1 50%',
    },
    '& > .contact': {
      flex: 1,
      padding: 0,
    },
  },
  name: {
    color: theme.palette.secondary[900],
    fontWeight: 'bold',
  },
  title: {
    color: theme.palette.secondary[700],
    marginBottom: '2rem',
  },
  section: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0.25rem 0',
  },
  sectionContainer: {
    margin: '1rem 0',
    display: 'flex',
    alignItems: 'center',
    '& > :first-child': {
      flex: '0 0 180px',
    },
    '& > :last-child': {
      flex: '1 1 100%',
      alignItems: 'flex-start',
      minHeight: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      '& > :first-child': {
        flex: '0',
      },
      '&.single': {
        alignItems: 'flex-start',
      },
    },
    '&.multi': {
      alignItems: 'flex-start',
    },
  },
  sectionHeader: {
    color: theme.palette.secondary[900],
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      marginBottom: '.75rem',
    },
  },
  divider: {
    margin: '0.5rem 0',
  },
  workContainer: {
    '& > :last-child': {
      marginBottom: 0,
    },
    '& .MuiList-padding:last-child': {
      paddingBottom: 0,
    },
  },
  work: {
    marginBottom: '1.5rem',
    '& .pageBreak': {
      display: 'none',
    },
    '& > .MuiList-root > .MuiListItem-root': {
      marginLeft: '-1.875rem',
      display: 'list-item',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
      },
      '& .bulletContainer': {
        display: 'flex',
      },
      '& .bullet': {
        display: 'flex',
        flexGrow: 0,
        flexShrink: 1,
        fontSize: '0.875rem',
        marginTop: '0.125rem',
        marginRight: '0.875rem',
        color: theme.palette.secondary[100],
      },
    },
  },
  companyName: {
    fontWeight: 700,
  },
  position: {
    fontWeight: 500,
  },
  subtitle: {
    color: theme.palette.grey[800],
  },
  skill: {
    marginRight: '.5rem',
    marginBottom: '.5rem',
    backgroundColor: theme.palette.secondary[900],
  },
  references: {
    fontStyle: 'italic',
    color: theme.palette.secondary[700],
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      textAlign: 'center',
    }
  },
  me: {},
  contact: {},
  '@media print': {
    root: {
      margin: 0,
      paddingBottom: 0,
      minHeight: '100vh',
      width: '100vw',
      border: 'none !important',
      borderColor: 'white',
      borderRadius: 'initial !important',
      boxShadow: 'none !important',
      transition: 'none',
    },
    '.MuiPaper-elevation12': {
      boxShadow: 'none',
    },
    sectionContainer: {
      flexDirection: 'row',
      pageBreakInside: 'auto',
      '& > :first-child': {
        flex: '0 0 110px',
      },
      '& > :last-child': {
        flex: '1 1 100%',
      },
      '&.single': {
        alignItems: 'center',
      },
    },
    sectionHeader: {
      marginBottom: 0,
    },
    work: {
      '& .pageBreak': {
        display: 'inline-block',
        width: '100%',
        pageBreakBefore: 'always',
        breakBefore: 'always',
        color: 'white',
      },
      '& .pageBreak:before': {
        display: 'block',
        content: '" "',
        width: '100%',
        height: '30px',
        pageBreakAfter: 'always',
        breakAfter: 'always',
        color: 'white',
      },
      '& > .MuiList-root > .MuiListItem-root': {
        marginLeft: '-1.875rem',
      },
      '& .workItem': {
        padding: 0,
        width: '100%',
        border: '0.1px solid white',
      },
      '& .bulletContainer': {
        display: 'block',
      },
    },
  },
}));
