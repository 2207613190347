import React from 'react';

import SEO from '../components/seo';
import { Layout } from '../components/Layout';
import { Resume } from '../components/Resume';

const IndexPage: React.FC = () => (
  <Layout>
    <SEO />
    <Resume />
  </Layout>
);

export default IndexPage;

