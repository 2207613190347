import React from 'react';
import { format } from 'date-fns';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';

import resume from  '../../resume.json'
// import { ContactInfo } from '../ContactInfo';
import { useStyles } from './styles';

export const Resume: React.FC = () => {
  const styles = useStyles();

  return (
    <Paper classes={{root: styles.root}} elevation={12}>
      <section className={styles.infoContainer}>
        <div className="me">
          <Typography variant="h3" component="h1" className={styles.name}>{resume.basics.name}</Typography>
          <Typography variant="h5" component="h2" className={styles.title} gutterBottom>{resume.basics.label}</Typography>
        </div>

        {/* <ContactInfo /> */}
      </section>
  
      <section className={`${styles.sectionContainer} multi`}>
        <Typography variant="h5" component="h3" className={styles.sectionHeader}>About</Typography>
        <Typography>{resume.basics.summary}</Typography>
      </section>

      <Divider className={styles.divider} />

      <section className={`${styles.sectionContainer} multi`}>
        <Typography variant="h5" component="h3" className={styles.sectionHeader}>Experience</Typography>
        <div className={styles.workContainer}>
          {resume.work.map((work, workIndex) => (
            <article key={`${work.position}-${work.company}`} className={styles.work}>
              {work.company && <Typography variant="h6" component="h4" className={styles.companyName}>{work.company}</Typography>}
              <Typography className={styles.position}>{work.position}</Typography>
              <Typography variant="subtitle2" className={styles.subtitle} gutterBottom>
                {format(new Date(`${work.startDate}T00:00:00`), 'MMMM yyyy')} &ndash; {work.endDate ? format(new Date(`${work.endDate}T00:00:00`), 'MMMM yyyy') : 'present'}
              </Typography>
              <List dense>
                {work.highlights.map((highlight, highlightIndex, arr) => (
                  <ListItem key={highlight.slice(0, 20)} disableGutters>
                    {/* {workIndex === 2 && highlightIndex === 1 ? <div className="pageBreak">&nbsp;</div> : null} */}
                    {/* {workIndex === 2 && highlightIndex === 2 ? <div className="pageBreak">&nbsp;</div> : null} */}
                    <Grid container alignItems="flex-start" alignContent="flex-start" wrap="nowrap">
                      <Grid item className="bulletContainer"><FiberManualRecord className="bullet" /></Grid>
                      <Grid item className="workItem"><Typography variant="body2">{highlight.trim()}</Typography></Grid>
                    </Grid>
                    {workIndex === 1 && highlightIndex === arr.length - 1 ? (
                      <>
                        <div className="pageBreak">&nbsp;</div>
                        <div className="pageBreak">&nbsp;</div>
                      </>
                    ) : null}
                    {workIndex === 4 && highlightIndex === 0 ? (
                      <>
                        <div className="pageBreak">&nbsp;</div>
                      </>
                    ) : null}
                  </ListItem>
                ))}
              </List>
            </article>
          ))}
        </div>
      </section>

      <Divider className={styles.divider} />

      <section className={`${styles.sectionContainer} single`}>
        <Typography variant="h5" component="h3" className={styles.sectionHeader}>Education</Typography>
        <Typography variant="body2">
          {resume.education[0].institution} ({resume.education[0].startDate.slice(0, 4)} - {resume.education[0].endDate.slice(0, 4)}) &ndash; {resume.education[0].area}
        </Typography>
      </section>

      <Divider className={styles.divider} />

      <section className={`${styles.sectionContainer} multi`}>
        <Typography variant="h5" component="h3" className={styles.sectionHeader}>Skills</Typography>
        <Grid container alignItems="flex-start" alignContent="flex-start">
          {resume.skills.map((skill) => <Chip key={`${skill.name}`} label={skill.name} color="secondary" className={styles.skill} />)}
        </Grid>
      </section>

      <Divider style={{ margin: '0 0 0.5rem' }} />

      <section className={`${styles.sectionContainer} single`}>
        <Typography variant="body2" className={styles.references}>References available upon request</Typography>
      </section>

    </Paper>
  );
}

export default Resume;
