import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
  main: {
    backgroundColor: '#282c34',
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '@global': {
    '@media print': {
      '*': {
        colorAdjust: 'exact',
        printClorAdjust: 'exact',
      },
      html: {
        fontSize: '10pt',
        height: '99%',
      },
      body: {
        height: '99%',    
      },
    },
  },
});
