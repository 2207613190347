import React from 'react';

import { useStyles } from './styles';
// import './layout.css';

export const Layout: React.FC = ({ children }) => {
  const styles = useStyles();

  return (
    <main className={styles.main}>
      {children}
    </main>
  );
};

export default Layout;
